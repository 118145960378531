import Dexie, { type Table } from 'dexie'
import type Question from '../models/question'
import type Qcm from '../models/qcm'
import type { Config } from '../models/config'
import type Session from '../models/session'
import type Student from '../models/student'
import type Group from '../models/group'

export interface StoreData {
  uid: string
  data: string
}

export interface StoreQuestions {
  uid: string
  data: Question
}

export interface StoreQcms {
  uid: string
  data: Qcm
}

export interface StoreSettings {
  uid: string
  data: Config
}

export interface StoreSessions {
  uid?: number
  data: Session
}

export interface StoreStudents {
  uid?: number
  data: Student
}

export interface StoreGroups {
  uid: number
  data: Group
}

export interface LinksGroupsStudents {
  uid?: number
  groupid: string
  studentid: string
}

class MyDatabase extends Dexie {
  store_data!: Table<StoreData, string>
  store_questions!: Table<StoreQuestions, string>
  store_qcms!: Table<StoreQcms, string>
  store_settings!: Table<StoreSettings, string>
  store_sessions!: Table<StoreSessions, number>
  store_students!: Table<StoreStudents, number>
  store_groups!: Table<StoreGroups, number>
  links_groups_students!: Table<LinksGroupsStudents, number>

  constructor () {
    super('db_qcmcam2')
    this.version(4).stores({
      store_data: 'uid,data',
      store_questions: 'uid,data',
      store_qcms: 'uid,data',
      store_settings: 'uid,data',
      store_sessions: '++uid,data',
      store_students: '++uid,data',
      store_groups: 'uid,data',
      links_groups_students: '++uid,groupid,studentid'
    })
  }
}

const db = new MyDatabase()

export const storage = {
  isAvailable (): boolean {
    if (this.db === null) return false
    return true
  },
  db,
  session: {
    get (key: string) {
      const value = sessionStorage.getItem(key)
      if (value !== null) return value
    },
    set (key: string, value: string) {
      sessionStorage.setItem(key, value)
    }
  }
}

export default storage
