<script lang="ts">
  import { onDestroy, onMount } from 'svelte'
  import { DEFAULT_VIEW, isAction, isView, type Action, type View } from '../lib/ts/models/navigation'
  import '../lib/js/katex/katex.min.css'
  import { config, currentAction, currentView, groups, qcms } from '../lib/ts/services/store'
  import Header from './header/Header.svelte'
  import Home from './home/Home.svelte'
  import QCM from './qcm/QCM.svelte'
  import GroupComponent from './group/Group.svelte'
  import Marker from './marker/Marker.svelte'
  import About from './about/About.svelte'
  import Stats from './stats/Stats.svelte'
  import Show from './show/Show.svelte'
  import storage, { type StoreData, type StoreQcms } from '../lib/ts/services/storage'
  import Group from '../lib/ts/models/group'
  import QcmsList from '../lib/ts/models/qcmsList.js'
  import language from '../lib/ts/services/language'
  import { saveSettings } from '../lib/ts/services/settings'
  import { updateColors } from '../lib/ts/services/colors'
  import type { Config } from '../lib/ts/models/config'
  import { setQCMQuestionFontSize, setQuestionFontSize } from '../lib/ts/services/qcm'

  onMount(() => {
    language.getNavigatorLanguage()
    updateDataFromStorage()
    updateParams()
    addEventListener('popstate', updateParams)
  })

  onDestroy(() => {
    removeEventListener('popstate', updateParams)
  })

  window.onresize = () => { setQuestionFontSize(); setQCMQuestionFontSize() }

  function updateDataFromStorage () {
    if (storage.isAvailable()) {
      storage.db.store_groups.toArray()
        .then(async (result: Array<{ uid: number, data: Group }>) => {
          result.forEach((element: { uid: number, data: Group }) => {
            Group.remake(element.data, element.uid).then((group: Group) => {
              $groups.push(group)
            }).catch(() => { console.log('Pb de chargement des groupes', element.uid) })
          })
        })
        .catch(() => {
          console.log('Pb de chargement des groupes')
        })

      storage.db.store_data.get('QCMCamQcms')
        .then((item: StoreData | undefined) => {
          if (item === undefined) {
            console.log('Pas de Qcms')
            return
          }
          $qcms.list = QcmsList.remake(item.data)
        })
        .catch(() => {
          console.log('Pas de Qcms')
        })

      // check if all QCM are in the list
      storage.db.store_qcms.each((item: StoreQcms) => {
        // create qcm ids list from qcms.list
        const qcmIds = $qcms.list.map((qcm) => { return qcm[0] })
        let nomoreQcms = true
        if (!qcmIds.includes(item.data._id)) {
          try {
            $qcms.list = [...$qcms.list, [item.data._id, item.data._name, item.data._questionsList[0], item.data._questionsList.length]]
            nomoreQcms = false
          } catch (err) {
            console.warn(err)
          }
        }
        if (!nomoreQcms) $qcms.save()
      })

      storage.db.store_settings.get('config')
        .then((item: { data: Config } | undefined) => {
          if (item === undefined) {
            console.log('Pas de config dans la base')
            return
          }
          restoreSettings(item.data)
        })
        .catch(() => {
          console.log('Pas de config dans la base')
        })
    }
  }

  function restoreSettings (settings: Config) {
    if (settings.changeOpinion !== undefined) {
      $config.changeOpinion = settings.changeOpinion
    }
    if (settings.scanAutoStop !== undefined) {
      $config.scanAutoStop = settings.scanAutoStop
    }
    if (settings.startMessage !== undefined) {
      $config.startMessage = settings.startMessage
    }
    if (settings.language !== undefined) {
      $config.language = settings.language
      // refresh page
      language.setLanguage(settings.language)
      saveSettings()
    }
    if (settings.defaultColors !== undefined) {
      $config.defaultColors = settings.defaultColors
      updateColors()
    }
  }

  function updateParams () {
    const url = new URL(window.location.href)
    const selectedView = url.searchParams.get('view')
    const selectedAction = url.searchParams.get('action')
    let newView: View = DEFAULT_VIEW
    let newAction: Action = ''
    if (isView(selectedView)) {
      newView = selectedView
    }
    if (isAction(selectedAction)) {
      newAction = selectedAction
    }
    if (newView !== $currentView) currentView.set(newView)
    if (newAction !== $currentAction) currentAction.set(newAction)
  }

</script>

<Header />
{#if $currentView === 'qcm'}
  <QCM />
{:else if $currentView === 'group'}
  <GroupComponent />
{:else if $currentView === 'stats'}
  <Stats />
{:else if $currentView === 'show'}
  <Show />
{:else if $currentView === 'marker'}
  <Marker />
{:else if $currentView === 'about'}
  <About />
{:else}
  <Home />
{/if}
