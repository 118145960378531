<script lang="ts">
    import { onMount, tick } from 'svelte'
    import { runGenerator, displayList } from '../../../lib/ts/generator'

  onMount(async () => {
    await tick()
    runGenerator()
  })
  let istwoPerPage = false
  let iswithname = false
</script>

<svelte:head>
  <title>Générateur de cartes Aruko 4 bits</title>
</svelte:head>

<div class="w3-center">
<div class="noprint">
      <h1 style="font-size:1.3em; text-align:center;" class="noprint">Générateur de cartes Aruco</h1>
      <br>
      <div class="btn-switch">
        <input type="radio" value="yes" id="wnyes" name="withname"
          class="btn-switch__radio btn-switch__radio_yes" checked on:click={() => {iswithname = true; displayList(iswithname)}}>
        <input type="radio" checked value="no" id="wnno" name="withname"
          class="btn-switch__radio btn-switch__radio_no" on:click={() => {iswithname = false; displayList(iswithname)}}>
        <label for="wnyes" class="btn-switch__label btn-switch__label_yes">
          <span class="btn-switch__txt">
            <i class="ri-user-unfollow-line qm-rel-top1"></i> Cartes anonymes
          </span>
        </label>
        <label for="wnno" class="btn-switch__label btn-switch__label_no">
          <span class="btn-switch__txt">
            <i class="qm-rel-top1 ri-user-follow-line"></i> Cartes nominatives
          </span>
        </label>
      </div>
      <div class="btn-switch">
        <input type="radio" value="yes" id="myes" name="markers" class="btn-switch__radio btn-switch__radio_yes" checked>
        <input type="radio" checked value="no" id="mno" name="markers"
          class="btn-switch__radio btn-switch__radio_no">
        <label for="myes" class="btn-switch__label btn-switch__label_yes">
          <span class="btn-switch__txt">
            <i class="qm-rel-top1 ri-bill-line"></i> Carte unique &nbsp; &nbsp; &nbsp;
          </span>
        </label>
        <label for="mno" class="btn-switch__label btn-switch__label_no">
          <span class="btn-switch__txt">
            <i class="qm-rel-top1 ri-file-copy-2-line"></i> Plusieurs cartes
          </span>
        </label>
      </div>
      <br>
      <div class="btn-switch">
        <input type="radio" value="one" id="mppyes" name="markersperpage" class="btn-switch__radio btn-switch__radio_yes" checked on:click={() => istwoPerPage = false}>
        <input type="radio" checked value="two" id="mppno" name="markersperpage"
          class="btn-switch__radio btn-switch__radio_no" on:click={() => istwoPerPage = true}>
        <label for="mppyes" class="btn-switch__label btn-switch__label_yes">
          <span class="btn-switch__txt">
            <i class="qm-rel-top1 ri-file-line"></i> Une carte par page &nbsp; &nbsp; &nbsp;
          </span>
        </label>
        <label for="mppno" class="btn-switch__label btn-switch__label_no">
          <span class="btn-switch__txt">
            <i class="qm-rel-top1 ri-file-fill"></i> Deux cartes par page
          </span>
        </label>
      </div>
      <div class="w3-border w3-round-xxlarge l200">
        Couleur des lettres : <input type="color" class="noborder" value="#E0E0E0" id="colorsetter">
      </div>
      <div id="generateOne" class="w3-border w3-round-xxlarge l200">
        Générer carte n° : <input type="number" min="1" max="120" step="1" name="valeur" id="mid" style="width:3.5em;" value="1">
      </div>
      <div id="generateMulti" class="w3-border w3-round-xxlarge l300 hidden">
        Générer de la carte n° <input type="number" min="1" max="120" step="1" name="valeur" id="minid" style="width:3.5em;" value="1">
        à la carte <input type="number" min="2" max="120"
          step="1" name="valeurmax" id="maxid" style="width:3.5em;" value="2">
      </div>
      <div id="importGroupList" class="w3-border w3-round-xxlarge l300 hidden">
        Importer une classe : <input type="file" id="file" accept="text/plain, .csv" title="Charger les élèves depuis son ordinateur" class="inputfile">
        <select id="classes" class="hidden">
          <option value="0" title="Importer d'abord des classes pour pouvoir générer des fichiers nomminatifs">
            Importer un fichier</option>
        </select>
      </div>

      <div id="log"></div>
    </div>
    <div id="markercanvas" style="display: none;"></div>
    <div id="png-container"
    class="flex flex-col items-center justify-center {istwoPerPage ? 'twoPerPage' : ''}"
    title="Clic droit copier pour coller dans un document.">
  <div class="centeredflex">
    <img src="" alt="">
  </div>
  </div>
  </div>
<style>
  #png-container {
    margin: 2em;
  }
@media print {
    .noprint,
    .noprint * {
        display: none !important;
        padding:0;
        margin:0;
    }
    #png-container {
        margin: 0
    }
    #png-container.twoPerPage div:nth-of-type(even) {
        page-break-after: always;
    }
    #png-container.twoPerPage div {
        page-break-inside: avoid;
        height: 420pt;
        border-bottom: 1pt solid #9e9e9e;
        border-right: 1pt solid #9e9e9e;
        aspect-ratio: 1 / 1;
    }
    #png-container:not(.twoPerPage) div {
        border-bottom: 1px solid #9e9e9e;
        aspect-ratio: 1 / 1;
        page-break-after: always;
    }
    .centeredflex {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

* {
    font-family: arial, sans-serif;
}

/* add pour font remix icon qui n'aligne pas bien verticalement */
.qm-rel-top1 {
    position: relative;
    top: 0.1em;
}
/* basiques */
.noborder {
    border: none;
    padding: 0;
}

.hidden {
    display: none;
}

/** largeur des "zones"
.l200 zone couleur et marqueur unique
.l300 zone import et marqueurs multiples
*/
.l200,
.l300 {
    max-width: 350px;
    padding: 0.4em;
    margin: auto;
    margin-top: 1em;
}

.l300 {
    max-width: 450px;
}

.l200 input,
.l300 {
    vertical-align: middle;
}

/* ajustement taille */
#png-container.twoPerPage img {
    height: 500px;
}

/* swith buttons */
/* div contenant le duo radio */
.btn-switch {
    font-size: 1em;
    position: relative;
    display: inline-block;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-top: 1em;
}
/* input:radio non affiché */
.btn-switch__radio {
    display: none;
}

/* label affichage commun
la couleur de police est celle commune,
*/
.btn-switch__label {
    display: inline-block;
    padding: .75em .5em .75em .75em;
    vertical-align: top;
    font-size: 1em;
    font-weight: 700;
    line-height: 1.5;
    color: #fff;
    cursor: pointer;
    transition: color .2s ease-in-out;
}
/* couleur de police du label gauche / premier déclaré */
.btn-switch__radio_yes:checked~.btn-switch__label_yes {
    color: #fff;
}
/* 2e label (celui de droite */
.btn-switch__label+.btn-switch__label {
    padding-right: .75em;
    padding-left: 0;
}
/* zone de texte des labels */
.btn-switch__txt {
    position: relative;
    z-index: 2;
    display: inline-block;
    min-width: 1.5em;
    opacity: 1;
    pointer-events: none;
    transition: opacity .2s ease-in-out;
}

/* zone de texte des labels de droite */
.btn-switch__radio_no:checked~.btn-switch__label_yes .btn-switch__txt,
.btn-switch__radio_yes:checked~.btn-switch__label_no .btn-switch__txt {
    opacity: 0;
    width: 2em;
    height: 1em;
    overflow: hidden;
}
/* forme et couleur des deux labels */
.btn-switch__label:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #64c6ea;
    border-radius: 1.5em;
    box-shadow: inset 0 .0715em .3572em rgba(43, 43, 43, .05);
    transition: background .2s ease-in-out;
}

/* couleur de fond du label de gauche / premier label déclaré */
.btn-switch__radio_yes:checked~.btn-switch__label:before {
    background: #2566fc;
}
/* rond qui coulisse pour le non - label droit */
.btn-switch__label_no:after {
    content: "";
    position: absolute;
    z-index: 2;
    top: .5em;
    bottom: .5em;
    left: .5em;
    width: 2em;
    background: #fff;
    border-radius: 1em;
    pointer-events: none;
    box-shadow: 0 .1429em .2143em rgba(43, 43, 43, .2), 0 .3572em .3572em rgba(43, 43, 43, .1);
    transition: left .2s ease-in-out, background .2s ease-in-out;
}
/* rond qui coulisse pour le oui, label gauche */
.btn-switch__radio_yes:checked~.btn-switch__label_no:after {
    left: calc(100% - 2.5em);
    background: #fff;
}
/* on passe devant le bouton non cliqué */
.btn-switch__radio_no:checked~.btn-switch__label_yes:before,
.btn-switch__radio_yes:checked~.btn-switch__label_no:before {
    z-index: 1;
}
</style>
