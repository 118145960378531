<script lang="ts">
  import Button from '../../shared/Button.svelte'
  import ModalQcmImport from '../ModalQCMImport.svelte'
  import { showModal } from '../../../lib/ts/services/modal'
  import Qcm from '../../../lib/ts/models/qcm'
  import { qcms } from '../../../lib/ts/services/store'
  import { displayQcmEditor, textToQuestionWidthFactor } from '../../../lib/ts/services/qcm'
  import utils from '../../../lib/ts/services/utils'
  import { onMount } from 'svelte'
  import { renderLatex } from '../../../lib/ts/services/katex'
  import type Question from '../../../lib/ts/models/question'
  import QcmSelectionCard from './QCMSelectionCard.svelte'
  import QcmSelectionLine from './QCMSelectionLine.svelte'

  export let selectedQcmId: number

  let miniatures: Question[] = []
  let isCardList = true

  onMount(() => {
    listQcms()
    qcms.subscribe(() => {
      listQcms()
    })
  })

  function listQcms () {
    $qcms.getMiniatures()
      .then(result => {
        miniatures = result
      })
      .then(() => {
        renderLatex('section-qcms-0')
        setQCMCardsFontSize()
      })
  }

  function addQcm () {
    const qcm = new Qcm('Titre', 'qcmcam2')
    const question = qcm.createQuestion('<h3>Question 1</h3>')
    question.addAnwser('')
    question.addAnwser('')
    question.save()
    $qcms.list.push([qcm.id, qcm.name, qcm.questionsList[0], 1])
    selectedQcmId = $qcms.list.length - 1
    displayQcmEditor()
  }

  function downloadQcms () {
    $qcms.export()
      .then(blob => {
        utils.file.download(blob, 'qcmcam-liste-des-qcm.txt')
      })
      .catch(err => {
        console.log(err)
      })
  }

  function setQCMCardsFontSize (): void {
    const questionContainers = document.querySelectorAll<HTMLElement>('#qcms-list .question-container')
    for (const questionContainer of Array.from(questionContainers)) {
      const width = questionContainer.getBoundingClientRect().width
      const pixelSize = width / textToQuestionWidthFactor
      questionContainer.style.fontSize = String(pixelSize) + 'px'
      utils.image.defaultPixelSize = pixelSize
    }
  }

  function updateSelectedQcmId (id: number): void {
    selectedQcmId = id
  }
</script>

<div class="tab">
  <nav class="navbar">
    <div class="navbar-menu">
      <div class="navbar-brand">
        <div class="navbar-item"> Commandes :</div>
      </div>
      <div class="navbar-start">
        <Button on:click={addQcm}>
          <i class="ri-play-list-add-line"></i>
          Créer un qcm
        </Button>
        <Button on:click={() => showModal('qm-import-qcm')}>
          <i class="ri-file-upload-line"></i>
          Importer des qcm
        </Button>
        <Button on:click={downloadQcms}>
          <i class="ri-file-download-line"></i>
          Télécharger
        </Button>
        <!-- <a id="btn-save-qcms"><i class="ri-save-line"></i> Enregistrer</a>-->
      </div>
    </div>
  </nav>
  <div id="qcms-list">
    <div class="flex flex-row border-b">
      <ul class="flex flex-row items-center">
        <li class="h-12 px-2 border-b border-transparent hover:border-black transition-colors duration-300 flex items-center"><button class="h-12">Mes questionnaires</button></li>
        <li class="h-12 px-2 border-b border-transparent hover:border-black transition-colors duration-300 flex items-center"><button class="h-12" title="Affichage des QCM" on:click={() => { isCardList = !isCardList }}><i class="ri-list-settings-line"></i></button></li>
        <li class="h-12 px-2 border-b border-transparent hover:border-black transition-colors duration-300 flex items-center"><button class="h-12" title="Ajouter une section"><i class="ri-add-line"></i></button></li>
      </ul>
    </div>
    <section id="section-qcms-0">
      {#if isCardList}
        {#each miniatures as miniature, index}
          <QcmSelectionCard
            qcms={$qcms}
            {index}
            {miniature}
            {listQcms}
            {updateSelectedQcmId}
            {displayQcmEditor}
          />
        {/each}
      {:else}
        {#each $qcms.list as question, index}
          <QcmSelectionLine
            qcms={$qcms}
            {index}
            {listQcms}
            {updateSelectedQcmId}
            {displayQcmEditor}
          />
        {/each}
      {/if}
    </section>
  </div>
</div>
<ModalQcmImport
  {listQcms}
  bind:selectedQcmId={selectedQcmId}
/>
