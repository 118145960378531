<script lang="ts">
  import Group from '../../lib/ts/models/group'
  import { hideModal } from '../../lib/ts/services/modal'
  import storage from '../../lib/ts/services/storage'
  import { groups } from '../../lib/ts/services/store'

  export let GroupList: (groups: Group[]) => void

  let modal: HTMLElement

  function openGroupsFile (event: Event): void {
    // le fichier est un txt avec lignes au format Numero\tNom[\tclasse] ou Nom seul
    if (event.target !== null) {
      const input = event.target as unknown as HTMLInputElement
      const reader = new FileReader()
      reader.onload = function () {
        if (typeof reader.result === 'string') {
          const result = Group.import(reader.result, $groups)
          if (result[1]) {
            groups.set(result[0])
            GroupList($groups)
          } else if (result[0] !== '') {
            GroupImportString(result[0], $groups)
          } else {
            hideModal(document.getElementById('qm-import-groups') as Element)
          }
        }
        //
      }
      if (input.files !== null) {
        const file = input.files[0]
        reader.readAsText(file)
      }
    }
  }

  async function GroupImportString (result: string, groups: Group[]): Promise<void> {
    if (result === '') return
    let importGroup: Group | undefined
    let groupNumber = 0
    let userNumber = 0
    const groupNames: string[] = []
    if (groups.length > 0) {
      if (!confirm('Ok pour ajouter les groupes\nAnnuler pour remplacer les groupes.')) {
        if (storage.isAvailable()) {
          // delete all groups and students
          for (const group of groups) {
            await Group.deleteGroup(group)
          }
          groups = []
        }
      } else {
        for (const group of groups) {
          groupNames.push(group.name)
        }
      }
    }
    const defaultGroupName = 'import'
    const markersId: Record<string, number> = { import: 0 }
    // afficher les données d'une ligne pour effectuer les rapprochements de champs
    const selectIndexes: Record<number, string> = { 1: 'prenom', 2: 'nom', 3: 'groupe', 4: 'marqueur' }
    const correspondances: Record<number, number> = {}
    // prendre la première ligne
    const lines = result.split(/[\r\n]+/g)
    const line0 = lines[0].split(/[\t;,]/g)
    const line1 = lines[1].split(/[\t;,]/g)
    const selectContent = `
    <option value=''>Choix</option>
    <option>Prénom</option>
    <option>NOM</option>
    <option>groupe</option>
    <option>marqueur</option>
    `
    const domImport = document.getElementById('import-2nd')
    domImport?.classList.remove('is-hidden')
    if (domImport !== null) domImport.innerHTML = ''
    const table = document.createElement('table')
    const thead = document.createElement('thead')
    table.appendChild(thead)
    const TR1 = document.createElement('tr')
    thead.appendChild(TR1)
    const TD1 = document.createElement('td')
    TR1.appendChild(TD1)
    const TD2 = document.createElement('td')
    TR1.appendChild(TD2)
    const TD3 = document.createElement('td')
    TR1.appendChild(TD3)
    const TR2 = document.createElement('tr')
    thead.appendChild(TR2)
    const TH1 = document.createElement('th')
    TH1.innerText = 'Ligne 1'
    const TH2 = document.createElement('th')
    TH2.innerText = 'Ligne 2'
    const TH3 = document.createElement('th')
    TH3.innerText = 'Corresp.'
    TR2.appendChild(TH1)
    TR2.appendChild(TH2)
    TR2.appendChild(TH3)
    const chckbxIgnoreL0 = document.createElement('input')
    chckbxIgnoreL0.type = 'checkbox'
    chckbxIgnoreL0.id = 'ignorel0'
    chckbxIgnoreL0.value = 'true'
    chckbxIgnoreL0.title = 'ignorer'
    TD1.appendChild(chckbxIgnoreL0)
    const chckbxIgnoreL1 = document.createElement('input')
    chckbxIgnoreL1.type = 'checkbox'
    chckbxIgnoreL1.id = 'ignorel1'
    chckbxIgnoreL1.value = 'true'
    chckbxIgnoreL1.title = 'ignorer'
    TD2.appendChild(chckbxIgnoreL1)
    TD3.innerText = '<- Ignorer'
    const tbody = document.createElement('tbody')
    table.appendChild(tbody)
    for (const [index, txt] of line0.entries()) {
      const tr = document.createElement('tr')
      const td0 = document.createElement('td')
      td0.innerText = txt.replace(/^"|"$/g, '')
      const td1 = document.createElement('td')
      td1.innerText = line1[index].replace(/^"|"$/g, '')
      const td2 = document.createElement('td')
      const select = document.createElement('select')
      select.id = 'selectCorresp' + String(index)
      select.innerHTML = selectContent
      select.onchange = () => {
        if (select.selectedIndex > 0) {
          select.classList.add('green')
          correspondances[index] = select.selectedIndex
        } else {
          select.classList.remove('green')
          correspondances[index] = -1
        }
      }
      td2.appendChild(select)
      tr.appendChild(td0)
      tr.appendChild(td1)
      tr.appendChild(td2)
      tbody.appendChild(tr)
    }
    domImport?.appendChild(table)
    const newBtn = document.createElement('button')
    newBtn.type = 'submit'
    newBtn.classList.add('button', 'is-success')
    newBtn.id = 'btn-import-students'
    newBtn.innerText = 'Valider'
    domImport?.appendChild(newBtn)
    newBtn.onclick = async (evt) => {
      evt.preventDefault()
      for (const [index, line] of lines.entries()) {
        if (chckbxIgnoreL0.checked && index === 0) continue
        if (chckbxIgnoreL1.checked && index === 1) continue
        if (line === '') continue
        const values = line.split(/[\t;,]/g)
        const newUser: Record<string, string> = {}
        for (const [ind, value] of values.entries()) {
          if (correspondances[ind] > 0 && correspondances[ind] < 5) {
            newUser[selectIndexes[correspondances[ind]]] = value.trim().replace(/^"|"$/g, '')
          }
        }
        if (newUser.marqueur === undefined) {
          let markerId = 0
          if (newUser.groupe !== undefined) {
            if (markersId[newUser.groupe] === undefined) {
              markersId[newUser.groupe] = 0
            }
            markerId = ++markersId[newUser.groupe]
          } else {
            markerId = ++markersId[defaultGroupName]
          }
          newUser.marqueur = String(markerId)
        }
        if (newUser.groupe === undefined) {
          if (importGroup === undefined) {
            importGroup = new Group('Groupe importé')
            await importGroup.init()
          }
          await importGroup.addStudent(newUser.nom, newUser.prenom, Number(newUser.marqueur), [defaultGroupName])
        } else {
          if (!groupNames.includes(newUser.groupe)) {
            groupNumber++
            groupNames.push(newUser.groupe)
            const group = new Group(newUser.groupe)
            await group.init()
            console.log(group.id)
            groups.push(group)
          }
          await groups[groupNames.indexOf(newUser.groupe)].addStudent(newUser.nom, newUser.prenom, Number(newUser.marqueur), [newUser.groupe])
          userNumber++
        }
      }
      if (importGroup !== undefined) groups.push(importGroup)
      if (domImport !== null) {
        domImport.innerHTML = `<div class="has-text-danger has-text-weight-bold is-family-sans-serif">Import d${groupNumber > 1 ? 'e ' : '’'}${groupNumber} groupe${groupNumber > 1 ? 's' : ''} et de ${userNumber} élèves effectué</div>`
      }
      // sauvegarde de tous les groupes
      for (const group of groups) {
        await group.save()
      }
      GroupList(groups)
    }
  }

  function handleImportGroupTextInput (event: Event) {
    if ((event.target as HTMLInputElement).type === 'textarea') {
      GroupImportString((event.target as HTMLInputElement).value, $groups).catch(err => { console.log(err) })
    }
  }

</script>

<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- svelte-ignore a11y-click-events-have-key-events -->
<div
  class="modal"
  id="qm-import-groups"
  bind:this={modal}
>
  <div class="modal-background"
    on:click|self={() => hideModal(modal)}
  >
  </div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Import de groupes</p>
      <button
        class="delete closebutton"
        aria-label="close"
        on:click={() => hideModal(modal)}
      >
      </button>
    </header>
    <section class="modal-card-body">
      <!-- svelte-ignore a11y-label-has-associated-control -->
      <label class="label">Fichier à importer</label>
      <div class="file is-centered">
        <label class="file-label">
          <input
            class="file-input"
            type="file"
            name="resume"
            accept=".txt,.csv"
            on:change={(event) => { openGroupsFile(event) }}
          >
          <span class="file-cta">
            <span class="file-icon">
              <i class="ri-upload-2-line"></i>
            </span>
            <span class="file-label">
              Fichier…
            </span>
          </span>
          <span class="file-name">Fichier txt ou csv</span>
        </label>
      </div>
      <!-- svelte-ignore a11y-label-has-associated-control -->
      <label class="label">Données copiées/collées</label>
      <textarea class="input" placeholder="Coller ici les données" cols="50" rows="1" on:input={handleImportGroupTextInput}></textarea>
    </section>
    <section id="import-2nd" class="modal-card-body is-hidden">
      2de Étape de l'import
    </section>
    <footer class="modal-card-foot">
      <a href="forgotpassword.html">Importer des groupes ?</a>
    </footer>
  </div>
</div>
