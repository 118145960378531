<script lang="ts">
  import type Group from '../../../lib/ts/models/group'
  import detection from '../../../lib/ts/services/markerdetection'
  import { hideModal } from '../../../lib/ts/services/modal'
  import { currentGroup, tictoc } from '../../../lib/ts/services/store'
  import utils from '../../../lib/ts/services/utils'

  export let displaySubgroups: (group: Group) => void

  let modal: HTMLElement

  function createSubgroupsByVote (): void {
    if ($currentGroup === undefined) { console.warn('createSubgroupsByVote', 'currentGroup is undefined'); return }
    startVoteForSubgroup($currentGroup)
  }
  function startVoteForSubgroup (group: Group): void {
    if (group === undefined) { console.warn('startVoteForSubgroup', 'group is undefined'); return }
    const tictoc0 = $tictoc[0]
    if (tictoc0 !== null) {
      clearInterval(tictoc0)
      $tictoc[0] = null
    }
    $tictoc[0] = setInterval(() => {
      const mks: Record<number, string> | undefined = detection.detect()
      if (utils.object.getLength(mks) > 0 && mks !== undefined) {
        group.setSubgroupsByVotes(mks)
        displaySubgroups(group)
      }
    }, 100)
  }
  function createRandomSubgroups (): void {
    if ($currentGroup === undefined) { console.warn('createRandomSubgroup', 'currentGroup is undefined'); return }
    $currentGroup.randomSubgroups()
    displaySubgroups($currentGroup)
  }
  async function confirmSubGroups (): Promise<void> {
    if ($currentGroup === undefined) { console.warn('confirmSubGroups', 'currentGroup is undefined'); return }
    await $currentGroup.save()
  }
</script>

<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- svelte-ignore a11y-click-events-have-key-events -->
<div class="modal" id="qm-gp-maker" bind:this={modal}>
  <div class="modal-background"
    on:click|self={() => hideModal(modal)}
  ></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Créer des groupe</p>
      <button class="delete closebutton" aria-label="close" data-modal="gp-maker" on:click={() => hideModal(modal)}></button>
    </header>
    <section class="modal-card-body">
      <div class="colums">
        <div class="column">
          <label class="label">Groupe A</label>
          <div id="gpA-container"></div>
        </div>
        <div class="column">
          <label class="label">Groupe B</label>
          <div id="gpB-container"></div>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      <button class="button is-success is-outlined mx-2" on:click={() => createRandomSubgroups()}>Aléatoire</button>
      <button class="button is-primary is-outlined mx-2" on:click={() => { createSubgroupsByVote() }}>Appel</button>
      <button class="button is-danger is-outlined mx-2" on:click={() => { hideModal(utils.DOM.getById('qm-gp-maker')) }}>Annuler</button>
      <button class="button is-link mx-2" on:click={() => { confirmSubGroups().catch(err => { console.log(err) }); hideModal(utils.DOM.getById('qm-gp-maker')) }}>Valider</button>
    </footer>
  </div>
</div>
