<script lang="ts">
  import { hideQCMCamShow } from '../../lib/ts/services/modal'

  export let showNamesOfStudents: (id?: string) => void
  export let showGoodAnswersOfStudents: (id?: string) => void
  export let showAnswersOfStudents: (id?: string) => void
  export let displayStatsOfAnswers: (id?: string) => void
  export let slideAddCache: (id?: string) => void
  export let nextQuestion: (id?: string) => void
  export let showGoodAnswer: (id?: string) => void
  export let stopDetection: (id?: string) => boolean

  export function handleCommands (id: '' | '0' | '1'): void {
    const commands = document.getElementById('qcm-commands' + id)
    if (commands !== null) {
      commands.onclick = (event) => {
        if (event.target !== null) {
          let target: Element | null = event.target as Element
          while (target !== null && target.nodeName !== 'BUTTON') {
            target = target.parentNode as Element
          }
          if (target !== null) {
            const command = target.id
            launchCommand(command)
          }
        }
      }
    }
  }

  function launchCommand (command: string): void {
    let zero = ''
    if (command.indexOf('0') > 0) zero = '0'
    if (command.indexOf('hide') > 0) {
      slideAddCache(zero)
    } else if (command.indexOf('next') > 0) {
      nextQuestion(zero)
      if (zero === '') {
          document.querySelectorAll('#qcm-commands .ri-play-large-line, #qcm-commands1 .ri-play-large-line').forEach(el => { el.classList.remove('ri-play-large-line'); el.classList.add('ri-stop-large-line') })
      } else {
        document.querySelectorAll('#qcm-commands0 .ri-play-large-line').forEach(el => { el.classList.remove('ri-play-large-line'); el.classList.add('ri-stop-large-line') })
      }
    } else if (command.indexOf('goodAnswer') > 0) {
      showGoodAnswer(zero)
    } else if (command.indexOf('stop') > 0) {
      document.querySelectorAll('btn-')
      if (stopDetection(zero)) {
        if (zero === '') {
          document.querySelectorAll('#qcm-commands .ri-stop-large-line, #qcm-commands1 .ri-stop-large-line').forEach(el => { el.classList.remove('ri-stop-large-line'); el.classList.add('ri-play-large-line') })
        } else {
          document.querySelectorAll('#qcm-commands0 .ri-stop-large-line').forEach(el => { el.classList.remove('ri-stop-large-line'); el.classList.add('ri-play-large-line') })
        }
      } else {
        if (zero === '') {
          document.querySelectorAll('#qcm-commands .ri-play-large-line, #qcm-commands1 .ri-play-large-line').forEach(el => { el.classList.remove('ri-play-large-line'); el.classList.add('ri-stop-large-line') })
        } else {
          document.querySelectorAll('#qcm-commands0 .ri-play-large-line').forEach(el => { el.classList.remove('ri-play-large-line'); el.classList.add('ri-stop-large-line') })
        }
      }
    } else if (command.indexOf('stats') > 0) {
      displayStatsOfAnswers(zero)
    }
  }

</script>

<div id="QCMCamShow" class="is-hidden">
  <header class="closeShowButton"><button class="delete closebutton" aria-label="close" on:click={hideQCMCamShow}></button>
  </header>
  <div id="participant-container0" class="box">
    <button class="button" title="Montrer/cacher les noms" on:click={() => { showNamesOfStudents('0') }}><i class="ri-list-ordered-2"></i></button>
    <button class="button" id="btn-showGoodAnswers0" title="Montrer qui a juste"><i class="ri-check-line"></i></button>
    <button class="button" title="Montrer les réponses" on:click={() => { showAnswersOfStudents('0') }}><i class="ri-mail-open-line"></i></button>
    <button class="button" id="btn-selectOneUser0" title="Sélectionner un participant qui a répondu"><i class="ri-user-search-line"></i></button>
    <button class="button" id="btn-selectUserHasGood0" title="Sélectionner un participant qui a juste"><i class="ri-user-follow-line"></i></button>
    <button class="button" id="btn-selectUserHasBad0" title="Sélectionner un participant qui a faux"><i class="ri-user-unfollow-line"></i></button>
    <div id="participant-list0"></div>
  </div>
  <article id="commands-container" class="box has-text-centered">
    <span id="qcm-commands" on:click={() => handleCommands('')}>
    <button class="button" id="btn-slide-hide" title="Cacher la question"><i class="ri-prohibited-2-line"></i></button> <button class="has-text-weight-bold button qcm-numero" id="qcm-numero"> 1 / 5 </button> <button class="button"
    id="btn-slide-next"><i class="ri-skip-right-line"></i></button> <button class="button" id="btn-slide-goodAnswer"><i class="ri-question-mark"></i></button>
    <button class="button" id="btn-slide-stats"><i class="ri-bar-chart-2-line"></i></button>
      <button class="button" id="btn-slide-stop"><i class="ri-stop-large-line"></i></button></span>
  </article>
  <article id="camera-container" class="box">
    <img title="logo QCMCam" src="images/QCMCam-logo.png" alt="logo qcmcam" class="is-pulled-left">
    <canvas id="cameraCopy"></canvas>
  </article>
  <div id="qcm-commands0" on:click={() => handleCommands('0')}><button class="button" id="btn-slide-hide0" title="Cacher la question"><i class="ri-prohibited-2-line"></i></button> <button class="has-text-weight-bold button" id="qcm-numero0"> 1 / 5 </button> <button class="button"
    id="btn-slide-next0"><i class="ri-skip-right-line"></i></button> <button class="button" id="btn-slide-goodAnswer0"><i class="ri-question-mark"></i></button>
    <button class="button" id="btn-slide-stats0"><i class="ri-bar-chart-2-line"></i></button>
    <button class="button" id="btn-slide-stop0"><i class="ri-stop-large-line"></i></button></div>
  <div id="qcm-container0" class="box qcm-container">
  </div>
  <div id="qcm-commands1" on:click={() => handleCommands('1')}><button class="button" id="btn-slide-hide1" title="Cacher la question"><i class="ri-prohibited-2-line"></i></button> <button class="has-text-weight-bold button qcm-numero" id="qcm-numero1"> 1 / 5 </button> <button class="button"
    id="btn-slide-next1"><i class="ri-skip-right-line"></i></button> <button class="button" id="btn-slide-goodAnswer1"><i class="ri-question-mark"></i></button>
    <button class="button" id="btn-slide-stats"><i class="ri-bar-chart-2-line"></i></button>
    <button class="button" id="btn-slide-stop1"><i class="ri-stop-large-line"></i></button></div>
  <div id="qcm-container" class="box qcm-container">
  </div>
  <div id="participant-container" class="box">
    <button class="button" title="Montrer/cacher les noms" on:click={() => { showNamesOfStudents() }}><i class="ri-list-ordered-2"></i></button>
    <button class="button" title="Montrer qui a juste" on:click={() => { showGoodAnswersOfStudents() }}><i class="ri-check-line"></i></button>
    <button class="button" title="Montrer les réponses données" on:click={() => { showAnswersOfStudents() }}><i class="ri-mail-open-line"></i></button>
    <button class="button" id="btn-selectOneUser" title="Sélectionner un participant qui a répondu"><i class="ri-user-search-line"></i></button>
    <button class="button" id="btn-selectUserHasGood" title="Sélectionner un participant qui a juste"><i class="ri-user-follow-line"></i></button>
    <button class="button" id="btn-selectUserHasBad" title="Sélectionner un participant qui a faux"><i class="ri-user-unfollow-line"></i></button>
    <div id="participant-list"></div>
  </div>
</div>
