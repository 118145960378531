<script lang="ts">
    import type Group from '../../../lib/ts/models/group';
  import { hideModal } from '../../../lib/ts/services/modal'
  import { groups } from '../../../lib/ts/services/store'
  import utils from '../../../lib/ts/services/utils'

  export let startQCM: (groups: Group[]) => Promise<void>

  let modal: HTMLElement

</script>

<!-- svelte-ignore a11y-no-static-element-interactions -->
<!-- svelte-ignore a11y-click-events-have-key-events -->
<div class="modal" id="qm-callroll" bind:this={modal}>
  <div class="modal-background" on:click|self={() => hideModal(modal)}></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Appel</p>
      <button class="delete closebutton" aria-label="close" data-modal="qm-callroll" on:click={() => hideModal(modal)}></button>
    </header>
    <section class="modal-card-body">
      <label class="is-small">Présents</label>
      <div id="presents-container"></div>
      <label class="is-small">Absents</label>
      <div id="absents-container"></div>
    </section>
    <footer class="modal-card-foot is-justify-content-center">
      <button class="button is-light is-large" on:click={() => { utils.DOM.removeClass('qm-callroll', 'is-active'); startQCM($groups, true).catch(err => { console.log(err) }) }}>
        <img src="images/play-circle-line.svg" alt="Play button">
      </button>
    </footer>
  </div>
</div>
